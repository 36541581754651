<template>
  <v-col>
    <v-row>
      <v-col>
        <validation-observer ref="observer">
          <validation-provider
            v-slot="{ errors }"
            vid="answer"
          >
            <v-radio-group
              v-if="!question.multipleChoice"
              :value="value[question.id] ? value[question.id].answer : ''"
              :error-messages="errors"
              class="ma-0"
              hide-details="auto"
              @change="update('answer', $event)"
            >
              <v-radio
                v-for="item in question.items"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </v-radio-group>

            <v-checkbox
              v-else
              v-for="item in question.items"
              :input-value="value[question.id] ? value[question.id].answer : ''"
              :key="item.id"
              :label="item.title"
              :value="item.id"
              :error-messages="errors"
              class="mt-1"
              hide-details
              multiple
              @change="update('answer', $event)"
            />
            <p
              v-if="errors && question.multipleChoice"
              class="ma-0 mt-1 v-messages error--text"
            >
              {{ errors['0'] }}
            </p>
          </validation-provider>
        </validation-observer>
      </v-col>
    </v-row>
    <v-row
      v-if="needExplain"
    >
      <v-col
        cols="12"
      >
        <v-textarea
          :value="value[question.id].explain"
          :rows="3"
          label="Justificar"
          filled
          @input="update('explain', $event)"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>

  export default {

    props: {
      value: {
        type: Object,
        default: () => { return {} },
      },
      question: {
        type: Object,
        default: () => { return {} },
      },
      validation: {
        type: Object,
        default: () => { return {} },
      },
    },

    data: () => ({
      needExplain: false,
    }),

    watch: {
      validation (errors) {
        this.$refs.observer.setErrors(errors)
      }
    },

    mounted () {
      if (this.value[this.question.id]?.answer) {
        this.checkIfNeedExplain(this.value[this.question.id].answer)
      }
    },

    methods: {
      update(key, value) {
        const allAnswers = { ...this.value }
        allAnswers[this.question.id] = { ... allAnswers[this.question.id], [key]: value }
        this.$emit('input', allAnswers)

        if (key === 'answer') {
          this.checkIfNeedExplain(value)
        }

        this.$emit('change', value)
      },

      checkIfNeedExplain (answer) {
        if (this.question.multipleChoice) {
          return
        }

        const item = this.question.items.find((i) => i.id === answer)
        this.needExplain = item.explain ? true : false
      },
    },

  }
</script>
